<div class="popup-overlay">
  <div class="popup-content">
    <h2>Sign In or Create an Account</h2>
    <br />
    <p>
      You are currently using the chat as a guest. Log in to access your message
      history, which helps provide more accurate responses based on your past
      interactions.
    </p>
    <br />
    <button style="margin-right: 10px" (click)="onLogin()">Log In</button>
    <button (click)="onClosePopup()">Continue as Guest</button>
  </div>
</div>
