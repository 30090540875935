import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
})
export class AccountComponent {
  searchPrompt: string = '';
  courtResults: any[] = [];
  user: any;
  userCourts: any[] = [];
  isLoading: boolean = true;

  constructor(
    private http: HttpClient,
    public authService: AuthService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getUserFromLocalStorage();
    console.log('(account) user: ', this.user);
    this.getUserCourts();
  }

  onSearchCourt() {
    // Don't send request if the input is empty
    if (this.searchPrompt.trim().length === 0) {
      this.courtResults = [];
      return;
    }

    // Use ApiService to search for courts
    this.apiService.searchCourts(this.searchPrompt).subscribe({
      next: (results) => {
        this.courtResults = results; // Assuming backend returns an array of courts with full names
        console.log(this.courtResults);
        this.searchPrompt = ''; // Clear the input box after search
      },
      error: (error) => {
        console.error('Error fetching court results:', error);
      },
    });
  }

  // Function to send a request to add the court
  onAddCourt(court_id: string) {
    this.apiService.addCourt(court_id).subscribe({
      next: (response) => {
        console.log('Court added:', response);
        this.getUserCourts();
      },
      error: (error) => {
        console.error('Error adding court:', error);
      },
    });
  }

  // Function to get courts from user_court table
  getUserCourts() {
    this.isLoading = true;
    this.apiService.getUserCourts().subscribe({
      next: (results) => {
        this.userCourts = results;
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.error('Error fetching user courts:', error);
      },
    });
  }

  // Function to remove a court
  removeCourt(court_id: string) {
    this.apiService.removeCourt(court_id).subscribe({
      next: (response) => {
        console.log('Court removed:', response);
        this.getUserCourts();
      },
      error: (error) => {
        console.error('Error removing court:', error);
      },
    });
  }

  logout(): void {
    this.authService.logout();
    this.user = null;
  }
}
