import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-range-courts',
  templateUrl: './date-range-courts.component.html',
  styleUrls: ['./date-range-courts.component.css'],
})
export class DateRangeCourtsComponent {
  yearFrom: number | null = null;
  yearTo: number | null = null;
  readonly minYear = 1871;
  readonly maxYear = 2024;

  @Output() dateRangeChanged = new EventEmitter<{
    from: number | null;
    to: number | null;
  }>();

  ngOnInit() {
    const savedDateRange = localStorage.getItem('dateRange');
    if (savedDateRange) {
      const { from, to } = JSON.parse(savedDateRange);
      this.yearFrom = from || null;
      this.yearTo = to || null;
    }
  }

  onDateChange() {
    if (this.isDateRangeValid()) {
      this.dateRangeChanged.emit({ from: this.yearFrom, to: this.yearTo });
    } else {
      this.dateRangeChanged.emit({ from: null, to: null });
    }
  }

  isDateRangeValid(): boolean {
    const isFromInRange =
      this.yearFrom === null ||
      (this.yearFrom >= this.minYear && this.yearFrom <= this.maxYear);
    const isToInRange =
      this.yearTo === null ||
      (this.yearTo >= this.minYear && this.yearTo <= this.maxYear);

    if (!isFromInRange || !isToInRange) {
      return false;
    }

    if (this.yearFrom !== null && this.yearTo !== null) {
      return this.yearFrom <= this.yearTo;
    }

    return true;
  }

  isYearInRange(year: number | null): boolean {
    return year === null || (year >= this.minYear && year <= this.maxYear);
  }
}
