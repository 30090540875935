import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  user: any;

  constructor(public authService: AuthService, private meta: Meta) {}

  ngOnInit(): void {
    // Check if the user is already logged in and fetch user data
    if (this.authService.isLoggedIn()) {
      this.authService.getUser().subscribe(
        (user) => {
          console.log('User Data Retrieved:', user);
          this.user = user;
        },
        (error) => console.error('Not logged in', error)
      );
    }

    // Disable pinch-zoom and ctrl + scroll zoom
    document.addEventListener('wheel', this.preventZoom, { passive: false });
    document.addEventListener('keydown', this.preventZoomKeys, {
      passive: false,
    });
  }

  ngOnDestroy(): void {
    // Re-enable zoom when leaving the page
    document.removeEventListener('wheel', this.preventZoom);
    document.removeEventListener('keydown', this.preventZoomKeys);
  }

  loginWithGoogle(): void {
    console.log('Login Button Clicked');
    this.authService.loginWithGoogle();
  }

  loginWithMicrosoft(): void {
    console.log('Login Button Clicked');
    this.authService.loginWithMicrosoft();
  }

  loginWithApple(): void {
    console.log('Login Button Clicked');
    this.authService.loginWithApple();
  }

  // Prevent zoom in and zoom out.
  preventZoom(event: WheelEvent): void {
    // Prevent zooming with ctrl + mouse scroll
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }

  preventZoomKeys(event: KeyboardEvent): void {
    // Prevent zooming with ctrl + plus/minus or ctrl + 0
    if (
      event.ctrlKey &&
      (event.key === '+' || event.key === '-' || event.key === '0')
    ) {
      event.preventDefault();
    }
  }
}
