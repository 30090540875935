import {
  Component,
  Renderer2,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  HostListener,
  Input,
} from '@angular/core';
import { ApiService } from '../api.service';
import { marked } from 'marked';
import { v4 as uuidv4 } from 'uuid';
import TypeIt from 'typeit';
import { interval, Subscription } from 'rxjs';
import { Router } from '@angular/router';

interface SearchResult {
  role: string;
  content: string;
  refusal?: string;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent {
  showGuestPopup: boolean = false;
  showFilterPopup: boolean = false;
  sentMessage: string = '';
  resultFromBackend: any = 'Loading...';
  showDialogBox: boolean = false;
  messages: {
    apiResult: any;
    publicResult: any;
    stopped: boolean;
    sentMessage: string;
    resultFromBackend: any;
    animation: boolean;
    uuid: string;
    typeItInstance: any;
  }[] = [];
  loading: boolean = false;
  activeUUID: string | null = null;
  publicResponseReceived: boolean = false;
  apiResponseReceived: boolean = false;
  isStackedLayout: boolean = false;
  dateRange: { from: number | null; to: number | null } = {
    from: null,
    to: null,
  };
  selectedCourts: string[] = [];
  private messageCounter: number = 0;
  private readonly messageThreshold: number = 5;
  @Input() opinionId: number | null = null;

  @ViewChild('scrollContainer') private scrollContainer: ElementRef | null =
    null;

  // @ViewChild('rightContainer', { static: false }) rightContainer!: ElementRef;
  // rightContainerNative!: HTMLDivElement;

  // ngAfterViewInit() {
  //   this.rightContainerNative = this.rightContainer.nativeElement;
  //   console.log(this.rightContainerNative);
  // }

  constructor(
    private apiService: ApiService,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {}

  // This method will be called when the component is initialized
  ngOnInit(): void {
    this.loadPreviousMessages();
    this.loadStateFromStorage();
    // this.checkLayout();
  }

  // ----------------------------------
  // ----- Methods to handle chat -----
  // ----------------------------------

  // Triggered when a message is sent from the chat-box
  onMessageReceived(message: string) {
    const uuid = uuidv4();

    this.sentMessage = message;
    this.showDialogBox = true;

    const newMessage = {
      sentMessage: message,
      resultFromBackend: 'Loading...',
      animation: false,
      uuid: uuid,
      stopped: false,
      publicResult: '',
      apiResult: '',
      typeItInstance: null,
    };

    this.messages.push(newMessage);
    this.scrollToBottom();

    this.activeUUID = uuid;

    this.publicResponseReceived = false;
    this.apiResponseReceived = false;

    this.getCompletion(message, newMessage, uuid);

    // Show popup message in user is not authentiacated
    if (!localStorage.getItem('authToken')) {
      this.messageCounter++;

      if (this.messageCounter >= this.messageThreshold) {
        this.showGuestPopup = true;
        this.messageCounter = 0;
      }
    }
  }

  // Handle both public and API requests
  handleCompletion(
    prompt: string,
    messageUUID: string,
    messageRef: any,
    shouldScroll: boolean = true,
    payload: {
      courts: string[];
      dateRange: { from: number | null; to: number | null };
    }
  ) {
    this.loading = true;

    const timeoutDuration = 60000;
    const timeoutId = setTimeout(() => {
      const foundMessage = this.messages.find((m) => m.uuid === messageUUID);
      if (foundMessage && foundMessage.resultFromBackend === 'Loading...') {
        foundMessage.resultFromBackend = 'Request timed out.';
        foundMessage.animation = true;
        this.loading = false;
        this.activeUUID = null;
        this.cdRef.detectChanges();
        if (shouldScroll) {
          this.scrollToBottom();
        }
      }
    }, timeoutDuration);

    // Show feedback messages.
    this.startFeedbackLoop();

    // Handle public response
    if (!this.opinionId)
      this.apiService
        .postCompletionPublic({
          prompt,
          uuid: messageUUID,
        })
        .subscribe(
          (data: any) => {
            clearTimeout(timeoutId);

            const resultContent =
              data.searchResults && data.searchResults.length >= 2
                ? data.searchResults.find(
                    (result: SearchResult) => result.role === 'assistant'
                  )?.content || ''
                : '';

            const foundMessage = this.messages.find(
              (m) => m.uuid === messageUUID
            );
            if (foundMessage && !foundMessage.stopped) {
              foundMessage.resultFromBackend = resultContent;
              foundMessage.publicResult = resultContent;
              this.cdRef.detectChanges();
              if (resultContent.includes('NOT_FOUND')) {
                this.publicResponseReceived = true;
              } else {
                // this.stopFeedbackLoop();
                this.applyLineByLineRevealEffect(
                  foundMessage,
                  resultContent,
                  'public'
                );
              }
            } else if (foundMessage && foundMessage.stopped) {
              // foundMessage.animation = true;
              // this.stopFeedbackLoop();
              this.cdRef.detectChanges();
            }
            this.publicResponseReceived = true;
          },
          (error: any) => {
            clearTimeout(timeoutId);
            const foundMessage = this.messages.find(
              (m) => m.uuid === messageUUID
            );
            if (foundMessage) {
              this.publicResponseReceived = true;
              foundMessage.resultFromBackend =
                'An error occurred with the Public request.';
            }
          }
        );

    // Handle API response
    this.apiService
      .postCompletionAPI({
        prompt,
        uuid: messageUUID,
        ...payload,
      })
      .subscribe(
        (data: any) => {
          clearTimeout(timeoutId);
          var resultContent =
            data.searchResults && data.searchResults.length >= 2
              ? data.searchResults.find(
                  (result: SearchResult) => result.role === 'assistant'
                )?.content || ''
              : '';

          const foundMessage = this.messages.find(
            (m) => m.uuid === messageUUID
          );
          if (foundMessage && !foundMessage.stopped) {
            foundMessage.resultFromBackend += resultContent;
            foundMessage.apiResult = resultContent;
            this.cdRef.detectChanges();
            // this.stopFeedbackLoop();
            this.applyLineByLineRevealEffect(
              foundMessage,
              resultContent,
              'api'
            );
            this.cdRef.detectChanges();
          } else if (foundMessage && foundMessage.stopped) {
            // resultContent = '';
            // this.stopFeedbackLoop();
            this.cdRef.detectChanges();
          }
          this.apiResponseReceived = true;
          if (this.opinionId) {
            console.log('Stop waiting for a public response...');
            this.publicResponseReceived = true;
          }
        },
        (error: any) => {
          clearTimeout(timeoutId);
          const foundMessage = this.messages.find(
            (m) => m.uuid === messageUUID
          );
          if (foundMessage) {
            this.apiResponseReceived = true;
            foundMessage.resultFromBackend =
              'An error occurred with the API request.';
          }
        }
      );
  }

  // Call the backend to get the answer
  getCompletion(prompt: string, messageRef: any, messageUUID: string) {
    const payload = {
      courts: this.selectedCourts,
      dateRange: this.dateRange,
      opinionId: this.opinionId,
    };

    this.handleCompletion(prompt, messageUUID, messageRef, true, payload);
  }

  // Call resetCurrentMessages when starting a new chat
  startNewChat(): void {
    if (localStorage.getItem('authToken') == null) {
      this.router.navigate(['/chat']).then(() => {
        window.location.reload();
      });
    } else {
      this.apiService.resetCurrentMessages().subscribe(
        (response) => {
          this.router.navigate(['/chat']).then(() => {
            window.location.reload();
          });
        },
        (error) => {
          console.error('Error resetting messages:', error);
        }
      );
    }
  }

  copyAnswer(message: any) {
    // Get the public response container
    const publicElement = document.getElementById(`public-${message.uuid}`);
    const publicHTML = publicElement ? publicElement.innerText : '';

    // Get the API response container
    const apiElement = document.getElementById(`api-${message.uuid}`);
    const apiHTML = apiElement ? apiElement.innerText : '';

    // Combine both contents into one string
    const combinedHTML = `${publicHTML}\n\n${apiHTML}`;

    // Create a temporary element to copy the combined content
    const textarea = document.createElement('textarea');
    textarea.value = combinedHTML;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  stopGeneration() {
    const activeMessage = this.messages.find((m) => m.uuid === this.activeUUID);

    if (activeMessage && this.loading === true) {
      // Mark the message as stopped
      activeMessage.stopped = true;
      activeMessage.resultFromBackend = 'stopped';

      this.loading = false;
      this.activeUUID = null;
      this.cdRef.detectChanges();
      this.publicResponseReceived = false;
      this.apiResponseReceived = false;
      this.stopFeedbackLoop();
    }
  }

  handleSendClick() {
    if (this.loading) {
      this.stopGeneration();
    } else {
      this.onMessageReceived(this.sentMessage);
    }
  }

  handleCardClick(cardText: string) {
    this.onMessageReceived(cardText);
    const bodyElement = document.body;
    this.renderer.setStyle(bodyElement, 'display', 'block');
  }

  // -------------------------------------
  // --- Methods to get previous chats ---
  // -------------------------------------

  // Call the API to get previous messages
  loadPreviousMessages(): void {
    this.apiService.getCurrentMessages().subscribe(
      (data) => {
        const bodyElement = document.body;
        this.renderer.setStyle(bodyElement, 'display', 'block');
        // Get the API result.
        var apiResponse =
          data.previousMessages && data.previousMessages.length >= 2
            ? data.previousMessages.find(
                (result: SearchResult) => result.role === 'assistant'
              )?.content || ''
            : '';
        // Get the uuid result.
        var uuid =
          data.previousMessages && data.previousMessages.length >= 2
            ? data.previousMessages.find(
                (result: SearchResult) => result.role === 'assistant'
              )?.uuid || ''
            : '';
        // Get the prompt.
        var prompt =
          data.previousMessages && data.previousMessages.length >= 2
            ? data.previousMessages.find(
                (result: SearchResult) => result.role === 'user'
              )?.content || ''
            : '';

        // Directly push previous messages to the `messages` array
        this.messages.push({
          sentMessage: prompt,
          resultFromBackend: apiResponse,
          publicResult: null,
          apiResult: apiResponse,
          uuid: uuid,
          animation: true,
          stopped: false,
          typeItInstance: null,
        });

        if (this.messages.length !== 0) {
          this.showDialogBox = true;
        }

        // Render the previous messages.
        setTimeout(async () => {
          const apiElement = document.getElementById(`api-${uuid}`);

          this.waitForElement(`api-${uuid}`, async (apiElement: any) => {
            apiElement.innerHTML = await marked.parse(apiResponse);
          });
        }, 1);
        this.scrollToBottom();
        this.cdRef.detectChanges();
      },
      (error) => {
        console.error('Failed to load previous messages:', error);
      }
    );
  }

  waitForElement(id: string, callback: Function, interval = 100) {
    const element = document.getElementById(id);
    if (element) {
      callback(element);
    } else {
      setTimeout(() => this.waitForElement(id, callback, interval), interval);
    }
  }

  //-------------------------------------
  // ---------- Scroll methods ----------
  //-------------------------------------

  isAtBottom(): boolean {
    if (!this.scrollContainer) return false;
    const threshold = 150;
    const position =
      this.scrollContainer.nativeElement.scrollTop +
      this.scrollContainer.nativeElement.offsetHeight;
    const height = this.scrollContainer.nativeElement.scrollHeight;
    return height - position <= threshold;
  }

  scrollToBottom(): void {
    if (this.scrollContainer) {
      setTimeout(() => {
        this.scrollContainer!.nativeElement.scrollTop =
          this.scrollContainer!.nativeElement.scrollHeight;
      }, 100);
    }
  }

  //-------------------------------------
  //------- Feedback text methods -------
  //-------------------------------------

  showFeedback: boolean = false;
  feedbackMessage: string = '';
  feedbackSubscription!: Subscription;
  feedbackMessages: string[] = [
    'Checking cache...',
    'Fetching the document...',
    'Summarizing Data...',
    'Analyzing...',
    'Updating your session...',
  ];
  triggerFade: boolean = false;

  // Function to simulate feedback message updates
  startFeedbackLoop() {
    this.showFeedback = true;
    let index = 0;

    this.feedbackMessage = this.feedbackMessages[index];
    this.triggerFade = true;

    // Update feedback message every 2 seconds
    this.feedbackSubscription = interval(2000).subscribe(() => {
      this.triggerFade = false;
      setTimeout(() => {
        this.feedbackMessage = this.feedbackMessages[index];
        this.triggerFade = true;
      }, 100);

      index = (index + 1) % this.feedbackMessages.length;
    });
  }

  // Function to stop the feedback loop when the response is received
  stopFeedbackLoop() {
    if (this.feedbackSubscription) {
      this.feedbackSubscription.unsubscribe(); // Stop the interval
    }
    this.showFeedback = false;
    this.feedbackMessage = '';
    this.triggerFade = false;
  }

  //-------------------------------------
  //----------- Pop-up methods ----------
  //-------------------------------------

  // Close the popup
  closePopup() {
    this.showGuestPopup = false;
  }

  login() {
    this.router.navigate(['/login']);
  }

  handleFilterPopup() {
    this.showFilterPopup = !this.showFilterPopup;
  }

  // ------------------------------------
  // ---------- Resize Methods ----------
  // ------------------------------------

  // @HostListener('window:resize', ['$event'])
  // onResize(event: Event) {
  //   console.log('onresize is called');
  //   this.checkLayout();
  // }

  // private checkLayout(): void {
  //   const threshold = 768;
  //   if (window.innerWidth < threshold) {
  //     console.log('Layout is stacked (full-width for both panels).');
  //     this.isStackedLayout = true;
  //   } else {
  //     console.log('Layout is side-by-side.');
  //     this.isStackedLayout = false;
  //   }
  // }

  // ------------------------------------
  // ---------- Court Settings ----------
  // ------------------------------------
  onDateRangeChange(dateRange: { from: number | null; to: number | null }) {
    this.dateRange = dateRange;
    localStorage.setItem('dateRange', JSON.stringify(this.dateRange));
    console.log('Updated date range:', this.dateRange);
  }

  onSelectedCourtsChange(selectedCourts: string[]) {
    this.selectedCourts = selectedCourts;
    localStorage.setItem('selectedCourts', JSON.stringify(this.selectedCourts));
    console.log('Updated selected courts:', this.selectedCourts);
  }

  loadStateFromStorage() {
    const savedCourts = localStorage.getItem('selectedCourts');
    const savedDateRange = localStorage.getItem('dateRange');

    if (savedCourts) {
      this.selectedCourts = JSON.parse(savedCourts);
    }

    if (savedDateRange) {
      this.dateRange = JSON.parse(savedDateRange);
    }
  }

  // ------------------------------------
  // -------- Animation Effects ---------
  // ------------------------------------
  async applyLineByLineRevealEffect(
    messageRef: any,
    content: string,
    type: 'api' | 'public'
  ) {
    const htmlContent = await marked(content);

    // Create a temporary container to work with the HTML structure
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    // Get the target span element based on the message's uuid and type (api or public)
    const targetId = `${type}-${messageRef.uuid}`;
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      // Clear targetElement content initially
      targetElement.innerHTML = '';

      // Get all elements that need to be revealed with animation
      // Select all elements of interest
      const allElements = Array.from(
        tempDiv.querySelectorAll('p, li, h1, h2, h3, blockquote, table, ol, ul')
      );

      // Filter out nested elements to keep only the outermost ones
      const outermostElements = allElements.filter(
        (el) =>
          !allElements.some((otherEl) => otherEl !== el && otherEl.contains(el))
      );

      // Track the number of animations completed
      let completedAnimations = 0;
      this.stopFeedbackLoop();
      // Function to add elements one by one with a delay
      outermostElements.forEach((element, index) => {
        setTimeout(() => {
          // Clone element to apply fresh animations
          const animatedElement = element.cloneNode(true) as HTMLElement;
          animatedElement.classList.add('line-reveal');
          targetElement.appendChild(animatedElement);

          // Scroll to bottom as each element is added
          this.scrollToBottom();

          // When the animation ends on each element
          animatedElement.addEventListener('animationend', () => {
            completedAnimations += 1;

            // If all animations are done, call stopGeneration
            if (completedAnimations === outermostElements.length) {
              if (this.publicResponseReceived && this.apiResponseReceived) {
                this.stopGeneration();
              } else {
                console.log('starting the feedbackloop');
                this.startFeedbackLoop();
              }
            }
          });
        }, index * 1000);
      });
    }
  }
}
