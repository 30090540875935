import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrl: './filter-popup.component.css',
})
export class FilterPopupComponent {
  @Input() onHandlePopup!: () => void;
  selectedCourts: string[] = [];
  @Output() selectedCourtsChanged = new EventEmitter<string[]>();
  @Output() dateRangeChanged = new EventEmitter<{
    from: number | null;
    to: number | null;
  }>();

  dateRange: { from: number | null; to: number | null } = {
    from: null,
    to: null,
  };

  districts = [
    {
      name: 'Federal Appellate',
      courts: [
        {
          id: 'scotus',
          label: 'SCOTUS - Supreme Court of the United States',
          checked: false,
        },
        {
          id: 'ca11',
          label: 'CA11 - U.S. Court of Appeals for the Eleventh Circuit',
          checked: false,
        },
      ],
    },
    {
      name: 'Federal District',
      courts: [
        {
          id: 'flmd',
          label:
            'FLMD - U.S. District Court for the Middle District of Florida',
          checked: false,
        },
        {
          id: 'flnd',
          label:
            'FLND - U.S. District Court for the Northern District of Florida',
          checked: false,
        },
        {
          id: 'flsd',
          label:
            'FLSD - U.S. District Court for the Southern District of Florida',
          checked: false,
        },
      ],
    },
    {
      name: 'Bankruptcy',
      courts: [
        {
          id: 'flmb',
          label:
            'FLMB - U.S. Bankruptcy Court for the Middle District of Florida',
          checked: false,
        },
        {
          id: 'flnb',
          label:
            'FLNB - U.S. Bankruptcy Court for the Northern District of Florida',
          checked: false,
        },
        {
          id: 'flsb',
          label:
            'FLSB - U.S. Bankruptcy Court for the Southern District of Florida',
          checked: false,
        },
      ],
    },
    {
      name: 'State',
      courts: [
        { id: 'fla', label: 'FLA - Florida Supreme Court', checked: false },
        {
          id: 'fladistctapp',
          label: 'FLADistCtApp - Florida District Courts of Appeal',
          checked: false,
        },
      ],
    },
    {
      name: 'Other Legal Entities',
      courts: [
        {
          id: 'flaag',
          label: 'FLAAG - Florida Attorney General',
          checked: false,
        },
      ],
    },
  ];

  selectedDistrict = this.districts[0];

  constructor(private meta: Meta) {}

  ngOnInit(): void {
    document.addEventListener('wheel', this.preventZoom, { passive: false });
    document.addEventListener('keydown', this.preventZoomKeys, {
      passive: false,
    });

    const savedCourts = localStorage.getItem('selectedCourts');
    const savedDateRange = localStorage.getItem('dateRange');

    if (savedCourts) {
      this.selectedCourts = JSON.parse(savedCourts);
      // Update the 'checked' state for each court based on selectedCourts
      this.districts.forEach((district) => {
        district.courts.forEach((court) => {
          court.checked = this.selectedCourts.includes(court.id);
        });
      });
    } else {
      this.checkAllCourts(); // Check all courts by default if nothing is saved
    }

    if (savedDateRange) {
      this.dateRange = JSON.parse(savedDateRange);
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('wheel', this.preventZoom);
    document.removeEventListener('keydown', this.preventZoomKeys);
  }

  preventZoom(event: WheelEvent): void {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }

  preventZoomKeys(event: KeyboardEvent): void {
    if (
      event.ctrlKey &&
      (event.key === '+' || event.key === '-' || event.key === '0')
    ) {
      event.preventDefault();
    }
  }

  selectDistrict(district: any) {
    this.selectedDistrict = district;
  }

  onCourtCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    const courtShortname = checkbox.id;

    if (checkbox.checked) {
      if (!this.selectedCourts.includes(courtShortname)) {
        this.selectedCourts.push(courtShortname);
      }
    } else {
      this.selectedCourts = this.selectedCourts.filter(
        (name) => name !== courtShortname
      );
    }
    this.selectedCourtsChanged.emit(this.selectedCourts);
  }

  onDateRangeChange(dateRange: { from: number | null; to: number | null }) {
    this.dateRange = dateRange;
    this.dateRangeChanged.emit(this.dateRange);
    console.log('Updated date range:', this.dateRange);
  }

  checkAllCourts() {
    this.districts.forEach((district) => {
      district.courts.forEach((court) => {
        if (!court.checked) {
          court.checked = true;
          this.selectedCourts.push(court.id);
        }
      });
    });
    this.selectedCourtsChanged.emit(this.selectedCourts);
  }

  clearAllCourts() {
    this.districts.forEach((district) => {
      district.courts.forEach((court) => {
        court.checked = false;
      });
    });
    this.selectedCourts = [];
    this.selectedCourtsChanged.emit(this.selectedCourts);
  }
}
