import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './chat/chat.component';
import { HomeComponent } from './home/home.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { CardComponent } from './card/card.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './auth.service';
import { PricingComponent } from './pricing/pricing.component';
import { FaqComponent } from './faq/faq.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AccountComponent } from './account/account.component';
import { DocumentChatComponent } from './document-chat/document-chat.component';
import { AngularSplitModule } from 'angular-split';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PopupComponent } from './popup/popup.component';
import { AccordionCourtsComponent } from './accordion-courts/accordion-courts.component';
import { DateRangeCourtsComponent } from './date-range-courts/date-range-courts.component';
import { FilterPopupComponent } from './filter-popup/filter-popup.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CardComponent,
    HeaderComponent,
    LoginComponent,
    PricingComponent,
    FaqComponent,
    ChatComponent,
    ChatBoxComponent,
    AccountComponent,
    DocumentChatComponent,
    PopupComponent,
    AccordionCourtsComponent,
    DateRangeCourtsComponent,
    FilterPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    AngularSplitModule,
    PdfViewerModule,
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS, // Registering the interceptor globally
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
