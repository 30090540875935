import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
})
export class CardComponent {
  @Input() icon: string = '';
  @Input() text: string = '';
  @Output() cardClick = new EventEmitter<string>();

  safeIcon: SafeHtml = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.safeIcon = this.sanitizer.bypassSecurityTrustHtml(this.icon);
  }

  onCardClick() {
    console.log('Card clicked:', this.text);
    this.cardClick.emit(this.text);
  }
}
