<div class="document-chat-container">
  <div class="content-section">
    <as-split direction="horizontal" [gutterSize]="2" [unit]="'percent'">
      <as-split-area [size]="50">
        <app-chat [opinionId]="opinionId"></app-chat>
      </as-split-area>
      <as-split-area [size]="50">
        <!-- Show error -->
        <div *ngIf="showErrorMessage" style="color: aliceblue">
          {{ errorText }}
        </div>
        <!-- Show HTML content -->
        <div
          *ngIf="showHtmlContent"
          style="
            width: 100%;
            height: 100%;
            overflow: hidden;
            background-color: #f2f2f2;
            display: flex;
          "
        >
          <div class="pdf-viewer-scrollable" [innerHTML]="htmlContent"></div>
        </div>
        <!-- Show PDF -->
        <div *ngIf="!showErrorMessage && !showPlainText" class="pdf-viewer">
          <pdf-viewer
            [src]="src"
            [rotation]="0"
            [original-size]="false"
            [show-all]="true"
            [fit-to-page]="false"
            [zoom]="1"
            [zoom-scale]="'page-width'"
            [stick-to-page]="false"
            [render-text]="true"
            [external-link-target]="'blank'"
            [autoresize]="true"
            [show-borders]="false"
            style="width: 100%; height: 100%"
          ></pdf-viewer>
        </div>
      </as-split-area>
    </as-split>
  </div>
</div>
