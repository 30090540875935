<div class="navbar navbar-expand-lg fixed-top bg-body-tertiary">
  <div class="container-fluid">
    <!-- Navbar brand -->
    <div class="navbar-brand">
      <a
        class="navbar-brand d-flex align-items-center"
        [routerLink]="['/home']"
      >
        <img
          src="images/logo-no-bg.png"
          alt="Logo"
          width="30"
          height="30"
          class="d-inline-block align-text-top"
          style="margin-right: 5px"
        />
        <a style="font-weight: bold; color: aliceblue">DocketChat</a>
      </a>
    </div>

    <!-- Profile section-->
    <div *ngIf="isLoggedIn; else loginSection">
      <div
        class="dropdown ms-auto profile-section"
        role="button"
        data-bs-toggle="dropdown"
      >
        <ng-container *ngIf="user?.picture; else nameInitials">
          <img
            class="profile-picture"
            [src]="user.picture"
            alt="Profile Picture"
            loading="lazy"
            (error)="useInitials()"
          />
        </ng-container>
        <ng-template #nameInitials>
          <div class="profile-initial">
            {{ user?.displayName?.charAt(0) }}
          </div>
        </ng-template>

        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            <a class="dropdown-item d-flex align-items-center">
              <svg
                viewBox="0 0 16 16"
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                style="width: 20px; height: 20px"
                class="icon"
              >
                <path
                  fill="#ffffff"
                  d="M15.81 10l-2.5-5h0.69c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-0.79c-1.056-1.145-2.541-1.881-4.198-1.95l-0.012-0.05c0-0.552-0.448-1-1-1s-1 0.448-1 1v0.05c-1.681 0.073-3.178 0.807-4.247 1.947l-0.753 0.003c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.69l-2.5 5h-0.19c0 1.1 1.34 2 3 2s3-0.9 3-2h-0.19l-2.55-5.09c0.064-0.039 0.118-0.089 0.159-0.148 0.873-1.019 2.148-1.669 3.575-1.702l0.006 10.94h-1v1h-2v1h8v-1h-2v-1h-1v-10.94c1.418 0.03 2.679 0.682 3.524 1.693 0.053 0.084 0.117 0.145 0.193 0.186l-2.527 5.061h-0.19c0 1.1 1.34 2 3 2s3-0.9 3-2h-0.19zM5 10h-4l2-3.94zM11 10l2-3.94 2 3.94h-4z"
                />
              </svg>
              Default Courts
            </a>
          </li>
          <li>
            <a class="dropdown-item d-flex align-items-center">
              <svg
                viewBox="0 0 1920 1920"
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                style="width: 20px; height: 20px"
                class="icon"
              >
                <path
                  d="M1703.534 960c0-41.788-3.84-84.48-11.633-127.172l210.184-182.174-199.454-340.856-265.186 88.433c-66.974-55.567-143.323-99.389-223.85-128.415L1158.932 0h-397.78L706.49 269.704c-81.43 29.138-156.423 72.282-223.962 128.414l-265.073-88.32L18 650.654l210.184 182.174C220.39 875.52 216.55 918.212 216.55 960s3.84 84.48 11.633 127.172L18 1269.346l199.454 340.856 265.186-88.433c66.974 55.567 143.322 99.389 223.85 128.415L761.152 1920h397.779l54.663-269.704c81.318-29.138 156.424-72.282 223.963-128.414l265.073 88.433 199.454-340.856-210.184-182.174c7.793-42.805 11.633-85.497 11.633-127.285m-743.492 395.294c-217.976 0-395.294-177.318-395.294-395.294 0-217.976 177.318-395.294 395.294-395.294 217.977 0 395.294 177.318 395.294 395.294 0 217.976-177.317 395.294-395.294 395.294"
                  fill-rule="evenodd"
                ></path>
              </svg>
              Settings
            </a>
          </li>

          <li><hr class="dropdown-divider" /></li>

          <li>
            <a
              class="dropdown-item d-flex align-items-center"
              (click)="logout()"
            >
              <svg
                viewBox="0 0 24 24"
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                style="width: 20px; height: 20px"
                class="icon"
              >
                <path
                  d="M12.9999 2C10.2385 2 7.99991 4.23858 7.99991 7C7.99991 7.55228 8.44762 8 8.99991 8C9.55219 8 9.99991 7.55228 9.99991 7C9.99991 5.34315 11.3431 4 12.9999 4H16.9999C18.6568 4 19.9999 5.34315 19.9999 7V17C19.9999 18.6569 18.6568 20 16.9999 20H12.9999C11.3431 20 9.99991 18.6569 9.99991 17C9.99991 16.4477 9.55219 16 8.99991 16C8.44762 16 7.99991 16.4477 7.99991 17C7.99991 19.7614 10.2385 22 12.9999 22H16.9999C19.7613 22 21.9999 19.7614 21.9999 17V7C21.9999 4.23858 19.7613 2 16.9999 2H12.9999Z"
                ></path>
                <path
                  d="M13.9999 11C14.5522 11 14.9999 11.4477 14.9999 12C14.9999 12.5523 14.5522 13 13.9999 13V11Z"
                ></path>
                <path
                  d="M5.71783 11C5.80685 10.8902 5.89214 10.7837 5.97282 10.682C6.21831 10.3723 6.42615 10.1004 6.57291 9.90549C6.64636 9.80795 6.70468 9.72946 6.74495 9.67492L6.79152 9.61162L6.804 9.59454L6.80842 9.58848C6.80846 9.58842 6.80892 9.58778 5.99991 9L6.80842 9.58848C7.13304 9.14167 7.0345 8.51561 6.58769 8.19098C6.14091 7.86637 5.51558 7.9654 5.19094 8.41215L5.18812 8.41602L5.17788 8.43002L5.13612 8.48679C5.09918 8.53682 5.04456 8.61033 4.97516 8.7025C4.83623 8.88702 4.63874 9.14542 4.40567 9.43937C3.93443 10.0337 3.33759 10.7481 2.7928 11.2929L2.08569 12L2.7928 12.7071C3.33759 13.2519 3.93443 13.9663 4.40567 14.5606C4.63874 14.8546 4.83623 15.113 4.97516 15.2975C5.04456 15.3897 5.09918 15.4632 5.13612 15.5132L5.17788 15.57L5.18812 15.584L5.19045 15.5872C5.51509 16.0339 6.14091 16.1336 6.58769 15.809C7.0345 15.4844 7.13355 14.859 6.80892 14.4122L5.99991 15C6.80892 14.4122 6.80897 14.4123 6.80892 14.4122L6.804 14.4055L6.79152 14.3884L6.74495 14.3251C6.70468 14.2705 6.64636 14.1921 6.57291 14.0945C6.42615 13.8996 6.21831 13.6277 5.97282 13.318C5.89214 13.2163 5.80685 13.1098 5.71783 13H13.9999V11H5.71783Z"
                ></path>
              </svg>
              Log out
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Login and Sign up buttons -->
    <ng-template #loginSection>
      <div class="ms-auto auth-buttons">
        <button type="button" (click)="login()" class="btn login-button">
          Log in
        </button>
        <button type="button" (click)="login()" class="btn signup-button">
          Sign up
        </button>
      </div>
    </ng-template>
  </div>
</div>
