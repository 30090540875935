<div class="date-range mt-4">
  <h5 class="text-start" style="color: #a1a1a1">Date Range</h5>

  <div class="row">
    <div class="col-4 mb-3">
      <label class="m-0" for="yearFrom" style="color: #a1a1a1">From</label>
      <input
        id="yearFrom"
        type="number"
        class="form-control"
        [min]="minYear"
        [max]="maxYear"
        placeholder="YYYY"
        [(ngModel)]="yearFrom"
        (change)="onDateChange()"
      />
    </div>

    <div class="col-4 mb-3">
      <label for="yearTo" style="color: #a1a1a1">To</label>
      <input
        id="yearTo"
        type="number"
        class="form-control"
        [min]="minYear"
        [max]="maxYear"
        placeholder="YYYY"
        [(ngModel)]="yearTo"
        (change)="onDateChange()"
      />
    </div>
  </div>

  <div
    *ngIf="!isYearInRange(yearFrom) || !isYearInRange(yearTo)"
    class="text-danger"
  >
    Please enter a year between {{ minYear }} and {{ maxYear }}.
  </div>

  <div
    *ngIf="
      yearFrom !== null &&
      yearTo !== null &&
      yearFrom > yearTo &&
      isYearInRange(yearFrom) &&
      isYearInRange(yearTo)
    "
    class="text-danger"
  >
    "From" year should be less than or equal to "To" year.
  </div>
</div>
