import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  // ----------------------------------
  // --------- General Methods --------
  // ----------------------------------

  // Helper function to get the headers with the token
  private getHeaders(): HttpHeaders {
    const token = this.authService.getToken(); // Fetch the token
    return new HttpHeaders().set('Authorization', `Bearer ${token}`);
  }

  // Error handling.
  public handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  // ----------------------------------
  // ----------- Chat Methods ---------
  // ----------------------------------

  // Get the API response.
  postCompletionAPI(data: any): Observable<any> {
    console.log(data);
    const headers = this.getHeaders();
    return this.http
      .post<any>(`api/completionAPI`, data, { headers })
      .pipe(catchError(this.handleError));
  }

  // Get the public response.
  postCompletionPublic(data: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .post<any>(`api/completionPublic`, data, { headers })
      .pipe(catchError(this.handleError));
  }

  // Update the user session.
  updateSessionMessages(previousMessages: string): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .post<any>(`api/updateSessionMessages`, { previousMessages }, { headers })
      .pipe(catchError(this.handleError));
  }

  // Method to retrieve previous messages
  getCurrentMessages(): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .get<any>(`api/getCurrentMessages`, { headers })
      .pipe(catchError(this.handleError));
  }

  // Method to retrieve previous messages
  getSearchInfo(): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .get<any>(`api/getSearchInfo`, { headers })
      .pipe(catchError(this.handleError));
  }

  // Method to reset current messages
  resetCurrentMessages(): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .post<any>(`api/resetCurrentMessages`, {}, { headers })
      .pipe(catchError(this.handleError));
  }

  getPdfUrl(opinionId: number): Observable<HttpResponse<Blob>> {
    const headers = this.getHeaders();
    return this.http
      .get(`/api/pdfProxy/${opinionId}`, {
        headers,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(catchError(this.handleError));
  }

  getPlainText(opinionId: number): Observable<string> {
    return this.http
      .get(`api/getPlainText/${opinionId}`, {
        responseType: 'text',
      })
      .pipe(catchError(this.handleError));
  }

  // ----------------------------------
  // ---------- Court Methods ---------
  // ----------------------------------

  // Method to search courts by prompt
  searchCourts(prompt: string): Observable<any[]> {
    return this.http
      .get<any[]>(`/api/searchCourt?prompt=${prompt}`)
      .pipe(catchError(this.handleError));
  }

  // Method to add or update a court for the user
  addCourt(courtId: string): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .post<any>(`/api/addCourt`, { courtId }, { headers })
      .pipe(catchError(this.handleError));
  }

  // Get the courts associated with the current user
  getUserCourts(): Observable<any[]> {
    const headers = this.getHeaders();
    return this.http
      .get<any[]>(`/api/getUserCourts`, { headers })
      .pipe(catchError(this.handleError));
  }

  // Remove a court from the user_court table
  removeCourt(courtId: string): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .delete<any>(`/api/removeCourt`, { headers, body: { courtId } })
      .pipe(catchError(this.handleError));
  }
}
