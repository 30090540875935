import {
  Component,
  EventEmitter,
  Output,
  Input,
  ElementRef,
  Renderer2,
  AfterViewInit,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.css'],
})
export class ChatBoxComponent implements AfterViewInit {
  message: string = ''; // To store the input value
  @Output() messageSent = new EventEmitter<string>(); // Emit message to parent component
  @Output() sendClick = new EventEmitter<void>(); // Emit event to handle stop action
  @Input() loading: boolean = false; // Track loading state
  // @Input() rightContainer!: HTMLDivElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.adjustMainScrollableHeight();
    // this.positionChatBox();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.adjustMainScrollableHeight();
    // this.positionChatBox();
  }

  handleButtonClick() {
    if (this.loading) {
      this.sendClick.emit(); // Emit the stop action if loading is true
      console.log('Stop request sent.');
    } else {
      this.sendMessage(); // Otherwise, send the message
    }
  }

  sendMessage() {
    if (this.message.trim()) {
      this.messageSent.emit(this.message);
      this.message = '';
      this.resetTextareaHeight();
      this.adjustMainScrollableHeight();
      const bodyElement = document.body;
      this.renderer.setStyle(bodyElement, 'display', 'block');
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (this.loading && event.key === 'Enter') {
      event.preventDefault();
      console.log('Cannot send message while loading is in progress.');
      return;
    }
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  autoGrow(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
    this.adjustMainScrollableHeight();
  }

  resetTextareaHeight() {
    const textarea = this.el.nativeElement.querySelector(
      '.chat-input'
    ) as HTMLTextAreaElement;
    textarea.style.height = 'auto';
  }

  adjustMainScrollableHeight() {
    const chatBoxContainer = this.el.nativeElement.querySelector(
      '.chat-box-container'
    );
    const mainScrollable = document.querySelector(
      '.main.scrollable'
    ) as HTMLElement;

    if (chatBoxContainer && mainScrollable) {
      const chatBoxHeight = chatBoxContainer.offsetHeight;
      const newMainScrollableHeight = `calc(90vh - ${chatBoxHeight}px)`;
      this.renderer.setStyle(mainScrollable, 'height', newMainScrollableHeight);
    }
  }

  // positionChatBox() {
  //   if (this.rightContainer) {
  //     const containerRect = this.rightContainer.getBoundingClientRect();
  //     const containerCenterX = containerRect.left + containerRect.width / 2;

  //     this.el.nativeElement.style.position = 'fixed';
  //     this.el.nativeElement.style.bottom = '20px'; // Distance from the bottom
  //     this.el.nativeElement.style.left = `${containerCenterX}px`;
  //     this.el.nativeElement.style.transform = 'translateX(-400px)';
  //   }
  // }
}
