<main
  class="main"
  [class.scrollable]="showDialogBox"
  [class.card-view-limited]="!showDialogBox"
  #scrollContainer
>
  <div class="container-fluid">
    <div class="row">
      <button (click)="startNewChat()" class="new-chat-button">
        <svg
          class="new-chat-icon"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 3C7.85113 3 4 5.73396 4 10C4 11.5704 4.38842 12.7289 5.08252 13.6554C5.79003 14.5998 6.87746 15.3863 8.41627 16.0908L9.2326 16.4645L8.94868 17.3162C8.54129 18.5384 7.84997 19.6611 7.15156 20.5844C9.56467 19.8263 12.7167 18.6537 14.9453 17.1679C17.1551 15.6948 18.3969 14.5353 19.0991 13.455C19.7758 12.4139 20 11.371 20 10C20 5.73396 16.1489 3 12 3ZM2 10C2 4.26604 7.14887 1 12 1C16.8511 1 22 4.26604 22 10C22 11.629 21.7242 13.0861 20.7759 14.545C19.8531 15.9647 18.3449 17.3052 16.0547 18.8321C13.0781 20.8164 8.76589 22.2232 6.29772 22.9281C5.48665 23.1597 4.84055 22.6838 4.56243 22.1881C4.28848 21.6998 4.22087 20.9454 4.74413 20.3614C5.44439 19.5798 6.21203 18.5732 6.72616 17.4871C5.40034 16.7841 4.29326 15.9376 3.48189 14.8545C2.48785 13.5277 2 11.9296 2 10Z"
              fill="#a1a1a1"
            ></path>
            <path
              d="M12 6C11.4477 6 11 6.44771 11 7V9H9C8.44772 9 8 9.44771 8 10C8 10.5523 8.44772 11 9 11H11V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V11H15C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9H13V7C13 6.44771 12.5523 6 12 6Z"
              fill="#a1a1a1"
            ></path>
          </g>
        </svg>
        <span class="tooltip">New Chat</span>
      </button>

      <button (click)="handleFilterPopup()" class="filter-button">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M21 6H19M21 12H16M21 18H16M7 20V13.5612C7 13.3532 7 13.2492 6.97958 13.1497C6.96147 13.0615 6.93151 12.9761 6.89052 12.8958C6.84431 12.8054 6.77934 12.7242 6.64939 12.5617L3.35061 8.43826C3.22066 8.27583 3.15569 8.19461 3.10948 8.10417C3.06849 8.02393 3.03853 7.93852 3.02042 7.85026C3 7.75078 3 7.64677 3 7.43875V5.6C3 5.03995 3 4.75992 3.10899 4.54601C3.20487 4.35785 3.35785 4.20487 3.54601 4.10899C3.75992 4 4.03995 4 4.6 4H13.4C13.9601 4 14.2401 4 14.454 4.10899C14.6422 4.20487 14.7951 4.35785 14.891 4.54601C15 4.75992 15 5.03995 15 5.6V7.43875C15 7.64677 15 7.75078 14.9796 7.85026C14.9615 7.93852 14.9315 8.02393 14.8905 8.10417C14.8443 8.19461 14.7793 8.27583 14.6494 8.43826L11.3506 12.5617C11.2207 12.7242 11.1557 12.8054 11.1095 12.8958C11.0685 12.9761 11.0385 13.0615 11.0204 13.1497C11 13.2492 11 13.3532 11 13.5612V17L7 20Z"
              stroke="#a1a1a1"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
        <span class="tooltip">Filters</span>
      </button>

      <!-- Right Panel Column (Chat Interface) -->
      <div class="col-md-12" style="position: relative">
        <div class="d-flex justify-content-center align-items-center">
          <!-- Card Container (Visible when showDialogBox is false) -->
          <div
            *ngIf="!showDialogBox"
            class="fade-in-animation container custom-height d-flex justify-content-center flex-column align-items-center"
          >
            <img
              src="/images/logo-no-bg.png"
              alt="Logo"
              class="mb-4 d-none d-md-block"
              style="max-width: 100px"
            />
            <div class="text-center d-flex gap-3">
              <app-card
                [text]="
                  opinionId
                    ? 'Summarize this document in a single paragraph.'
                    : 'United Parcel Service, Inc. v. Flores-Galarza'
                "
                (cardClick)="handleCardClick($event)"
              ></app-card>

              <app-card
                [text]="
                  opinionId
                    ? 'Who are the judges and attorneys, and what is the name of the court?'
                    : 'The court concurred and dismissed the DUI charge'
                "
                (cardClick)="handleCardClick($event)"
              ></app-card>

              <app-card
                [text]="
                  opinionId
                    ? ' What is the outcome of this case? Explain in detail.'
                    : 'Search Docket number 03-1316 then summarize.'
                "
                (cardClick)="handleCardClick($event)"
              ></app-card>
            </div>
          </div>

          <!-- Dialog Box: Visible when a message is sent -->
          <div *ngIf="showDialogBox" class="container mt-3">
            <div *ngFor="let message of messages" class="row mb-4">
              <!-- User's Sent Message -->
              <div class="col-12 d-flex justify-content-end">
                <div
                  style="
                    color: #e5e5e5;
                    background-color: #2f2f2f;
                    border-radius: 20px;
                  "
                  class="p-3 shadow-sm"
                >
                  <p class="mb-0">
                    {{ message.sentMessage }}
                  </p>
                </div>
              </div>

              <!-- Response and Loading Section -->
              <div class="col-12 mt-2">
                <div class="p-3 rounded shadow-sm">
                  <div
                    *ngIf="message.resultFromBackend === 'Loading...'"
                    class="text-muted"
                  >
                    <div
                      *ngIf="message.uuid === activeUUID && showFeedback"
                      [ngClass]="{ 'fade-in-animation': triggerFade }"
                      class="feedback"
                    >
                      {{ feedbackMessage }}
                    </div>
                  </div>

                  <div *ngIf="message.resultFromBackend !== 'Loading...'">
                    <div class="text-center mb-3 d-flex align-items-center">
                      <img
                        src="/images/logo-no-bg.png"
                        class="me2"
                        alt="Logo"
                        style="width: 40px"
                      />
                    </div>
                    <div class="d-flex align-items-center">
                      <p
                        class="mb-0"
                        [ngClass]="{ 'fade-in-animation': message.animation }"
                        style="color: #e5e5e5"
                      >
                        <span
                          [id]="'public-' + message.uuid"
                          [innerHTML]=""
                        ></span>
                      </p>
                    </div>
                    <p
                      class="mb-0"
                      [ngClass]="{ 'fade-in-animation': message.animation }"
                      style="color: #e5e5e5"
                    >
                      <span [id]="'api-' + message.uuid" [innerHTML]=""></span>
                    </p>
                    <div
                      *ngIf="message.uuid === activeUUID && showFeedback"
                      class="feedback"
                    >
                      {{ feedbackMessage }}
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                      <button (click)="copyAnswer(message)" class="copy-button">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-copy"
                        >
                          <rect
                            x="9"
                            y="9"
                            width="13"
                            height="13"
                            rx="2"
                            ry="2"
                          ></rect>
                          <path
                            d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
                          ></path>
                        </svg>
                        <span class="tooltip">Copy</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Chat Box Component (Triggers onMessageReceived when message is sent) -->
          <div class="overlay-layer">
            <div class="center-container">
              <app-chat-box
                style="pointer-events: auto"
                (messageSent)="onMessageReceived($event)"
                [loading]="loading"
                (sendClick)="handleSendClick()"
              ></app-chat-box>
            </div>
          </div>

          <!-- Guest User Popup -->
          <div *ngIf="showGuestPopup">
            <app-popup
              [onClosePopup]="closePopup.bind(this)"
              [onLogin]="login.bind(this)"
            ></app-popup>
          </div>
          <!-- <div [ngClass]="{ 'd-none': !showFilterPopup }"> -->
          <div *ngIf="showFilterPopup">
            <app-filter-popup
              [onHandlePopup]="handleFilterPopup.bind(this)"
              (selectedCourtsChanged)="onSelectedCourtsChange($event)"
              (dateRangeChanged)="onDateRangeChange($event)"
            >
            </app-filter-popup>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
