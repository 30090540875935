<div class="fade-in">
  <div class="account-container">
    <h1>Welcome, {{ user.displayName }}!</h1>
    <br />
    <div class="item-divider"></div>
    <div class="email-container">
      <svg
        class="email-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -4 32 32"
        version="1.1"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
        fill="#FFFFFF"
      >
        <g
          id="Icon-Set"
          sketch:type="MSLayerGroup"
          transform="translate(-412.000000, -259.000000)"
          fill="#FFFFFF"
        >
          <path
            d="M442,279 C442,279.203 441.961,279.395 441.905,279.578 L433,270 L442,263 L442,279 L442,279 Z M415.556,280.946 L424.58,271.33 L428,273.915 L431.272,271.314 L440.444,280.946 C440.301,280.979 415.699,280.979 415.556,280.946 L415.556,280.946 Z M414,279 L414,263 L423,270 L414.095,279.578 C414.039,279.395 414,279.203 414,279 L414,279 Z M441,261 L428,271 L415,261 L441,261 L441,261 Z M440,259 L416,259 C413.791,259 412,260.791 412,263 L412,279 C412,281.209 413.791,283 416,283 L440,283 C442.209,283 444,281.209 444,279 L444,263 C444,260.791 442.209,259 440,259 L440,259 Z"
            id="mail"
            sketch:type="MSShapeGroup"
          ></path>
        </g>
      </svg>
      <h3>{{ user.email }}</h3>
    </div>
    <div class="item-divider"></div>

    <!-- Show spinner while loading courts -->
    <div *ngIf="isLoading" class="spinner"></div>

    <!-- List of Courts from user_court -->
    <div class="user-courts" *ngIf="userCourts.length > 0">
      <h3>My Courts:</h3>
      <ul>
        <li *ngFor="let court of userCourts">
          <button class="del-btn" (click)="removeCourt(court.id)">Del</button>
          {{ court.full_name }}
        </li>
      </ul>
    </div>
    <div class="item-divider"></div>

    <!-- Search court -->
    <div class="search-courts">
      <!-- Search bar -->
      <input
        type="text"
        [(ngModel)]="searchPrompt"
        placeholder="Search for courts..."
        class="search-bar"
      />
      <!-- Search button -->
      <button (click)="onSearchCourt()" class="search-button">
        <svg
          class="search-icon"
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 0 24 24"
          width="20px"
          fill="#000000"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zM9.5 14C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          />
        </svg>
      </button>
    </div>
    <!-- Search results -->
    <div class="court-results" *ngIf="courtResults.length > 0">
      <h3>Search Results:</h3>
      <ul>
        <li *ngFor="let court of courtResults">
          <button class="add-btn" (click)="onAddCourt(court.id)">Add</button>
          {{ court.full_name }}
        </li>
      </ul>
    </div>
  </div>
</div>
