import { Component, Input, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
})
export class PopupComponent {
  @Input() onClosePopup!: () => void;
  @Input() onLogin!: () => void;

  constructor(private meta: Meta) {}

  ngOnInit(): void {
    // Disable pinch-zoom and ctrl + scroll zoom
    document.addEventListener('wheel', this.preventZoom, { passive: false });
    document.addEventListener('keydown', this.preventZoomKeys, {
      passive: false,
    });
  }

  ngOnDestroy(): void {
    // Re-enable zoom when leaving the page
    document.removeEventListener('wheel', this.preventZoom);
    document.removeEventListener('keydown', this.preventZoomKeys);
  }

  // Prevent zoom in and zoom out.
  preventZoom(event: WheelEvent): void {
    // Prevent zooming with ctrl + mouse scroll
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }

  preventZoomKeys(event: KeyboardEvent): void {
    // Prevent zooming with ctrl + plus/minus or ctrl + 0
    if (
      event.ctrlKey &&
      (event.key === '+' || event.key === '-' || event.key === '0')
    ) {
      event.preventDefault();
    }
  }
}
