import { Component, ElementRef, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'my-angular-app';

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (!this.router.url.includes('/chat')) {
          this.applyBodyStyles();
        }
      });
  }

  applyBodyStyles() {
    const bodyElement = document.body;
    this.renderer.setStyle(bodyElement, 'display', 'flex');
    this.renderer.setStyle(bodyElement, 'align-items', 'center');
  }

  removeBodyStyles() {
    const bodyElement = document.body;
    this.renderer.removeStyle(bodyElement, 'align-items');
  }
}
