import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  title = 'ui';
  isLoggedIn = false;

  constructor(
    private authService: AuthService,
    private apiService: ApiService
  ) {
    // this.getCompletion();
  }

  ngOnInit(): void {
    // Check if the user is logged in and set the menu items accordingly
    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
    }
  }
}
