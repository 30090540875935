<div class="fade-in">
  <main class="main">
    <div class="container">
      <div
        class="custom-height row justify-content-center align-items-center vh-100"
      >
        <!-- Logo Column -->
        <div class="col-md-4 d-none d-md-flex justify-content-center">
          <img src="images/logo-no-bg.png" alt="Logo" class="img-fluid" />
        </div>

        <!-- Divider -->
        <div class="col-md-1 d-none d-md-block">
          <div class="divider"></div>
        </div>

        <div class="col-12 col-md-4">
          <div class="pricing-option btn">
            <h2 style="color: aliceblue">Free Trial</h2>
          </div>
          <hr class="hr-pricing" />
          <div class="pricing-option btn">
            <h2 style="color: aliceblue">$49.99/Month</h2>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
