<div
  class="fade-in faq-container custom-height row justify-content-center align-items-center vh-100"
>
  <div class="faq-container">
    <p style="color: aliceblue">
      The information your company provides is available for free online. Why
      should I pay for your website?
    </p>
    <hr style="color: aliceblue" />
    <br />
    <p class="faq-form">
      While it’s true that some information is available for free online, many
      AI websites aren’t actually free; they often track and sell your data,
      including your search history and personal details, to larger aggregators,
      which could be used to train AI models. With Docketchat, you can trust
      that your information is kept private. Unlike other services that charge
      $20 per month and may still track your activities, Docketchat prioritizes
      your privacy and ensures that your data is never shared.
    </p>
  </div>
  <div class="faq-container">
    <p style="color: aliceblue">Where do you get court documents?</p>
    <hr style="color: aliceblue" />
    <br />
    <p class="faq-form">
      We obtain our court documents through nonprofit organizations that
      specialize in legal transparency and public access to information. These
      organizations provide us with the necessary documents, ensuring that we
      can offer accurate and reliable information to the public.
      <a style="color: rgb(213, 213, 246)" href="https://free.law/"
        >https://free.law/</a
      >
    </p>
  </div>
  <div class="faq-container">
    <p style="color: aliceblue">
      Why use a mouse and other pointing devices? I thought I could just chat
      with the application.
    </p>
    <hr style="color: aliceblue" />
    <br />
    <p class="faq-form">
      Asking questions in natural language is intuitive, but we shouldn't forget
      the value of the mouse and other pointing devices, as typing can be slow
      and time-consuming. In our application, we offer both a command line
      interface and mouse-based features to give you a balanced and efficient
      solution.
    </p>
  </div>
  <div class="faq-container">
    <p style="color: aliceblue">
      If these are nonprofit organizations, the information should be free for
      me as well. Can you provide the link to search their documents?
    </p>
    <hr style="color: aliceblue" />
    <br />
    <p class="faq-form">
      Yes, the information is free and can be accessed through the following
      links. Our solution enhances the experience by making it more
      conversational and AI-powered.
      <a
        style="color: rgb(213, 213, 246)"
        href="https://www.courtlistener.com/opinion/"
        >Opinions</a
      >,
      <a
        style="color: rgb(213, 213, 246)"
        href="https://www.courtlistener.com/recap/"
        >RECAPS</a
      >
    </p>
  </div>
  <div class="faq-container">
    <p style="color: aliceblue">
      Can you provide me with legal advice or help me with my case?
    </p>
    <hr style="color: aliceblue" />
    <br />
    <p class="faq-form">
      We're sorry, but we are unable to provide legal advice or assistance with
      individual cases. We recommend contacting a qualified attorney for legal
      guidance. Many local bar associations, including your county's bar
      association, may offer a lawyer referral service that can connect you with
      an attorney for a no-cost or low-cost initial consultation.
    </p>
  </div>
</div>
